import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./NoInternet.css";

const NoInternet = (props) => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });
  

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  if (isOnline) {
    return props.children;
  } else {
    return (
      <>
              <div className="container my-5 d-flex justify-content-center">
                <div className="row  justify-content-center ">
                  <div className="col">
                {/* <Header/> */}
                    <div className="card ">
                      <div className="card-header pb-0 bg-white border-0 text-center px-sm-4">
                        <h6 className="text-left mt-4 font-weight-bold mb-0">
                          <span>
                            <i
                              className="fa fa-times-circle fa-2x mr-3 "
                              aria-hidden="true"
                            ></i>{" "}
                          </span>{" "}
                          NO INTERNET<span id="cont"> CONNECTION!</span>
                        </h6>{" "}
                        <span className="img-1 text-center">
                        </span>{" "}
                      </div>
                      <div className="card-body px-sm-4 mb-3">
                        <ul className="list-unstyled text-muted">
                          {" "}
                          <li id="des">
                            Please re-connect to the internet to continue use  
                            <span id="siteName"> {process.env.REACT_APP_SMALLNAME}</span>
                          </li>{" "}<br/>
                          <li>If you encounter problems:</li>
                          <ul className="mt-2 inner">
                            <li>
                              Try restarting wireless connection on this device.
                            </li>
                            <li>Move close to your wireless access point.</li>
                          </ul>
                        </ul>
                        <div className="row justify-content-end mt-4 ">
                          {" "}
                          {/* <div className="col-auto">
                            <button type="button" className="btn btn-success">
                              <span>Try Again</span>
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
      </>
    );
  }
};

export default NoInternet;
